// @use '@angular/material' as mat;
// @use "_theme_d3";
// @import "_theme_dark";
// @import "_theme_light";

// @include mat.core();

@use '@angular/material' as mat;

@include mat.core();

// $my-theme: mat.define-theme((
//  color: (
//     theme-type: light,
//     primary: mat.$violet-palette,
//   ),
// ));

// html {
//   @include mat.all-component-themes($my-theme);
// }


html,
body {
    height: 100%;
}

body {
    margin: 0px;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-display: swap;
}